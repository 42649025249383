<template>
  <div>
    <v-container>
      <h2 class="text-h5 mb-4">Bulk Operations</h2>

      <v-card class="mb-4" style="padding: 10px">
        <v-row>
         <v-col cols="12" md="3">
          <v-select
            @change="fetchOperations"
            v-model="filters.type"
            :items="['All', 'product', 'collection']"
            label="Bulk Operation Type"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="filters.shop"
            label="Search by specifier"
            @change="fetchOperations"
          ></v-text-field>
        </v-col>
        </v-row>

      </v-card>
      <v-data-table
        :headers="headers"
        :items="bulkOperations"
        :items-per-page="pagination.per_page"
        :items-length="pagination.total"
        class="elevation-1"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.specifier }}</td>
              <td>
              {{ item.bulk_query_id }}
              </td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.poll_progress_count }}</td>
              <td>{{ item.processed_count }}</td>
              <td>{{ item.step }}</td>
              <td>{{ item.total_count }}</td>
              <td>{{ item.total_count_precision }}</td>
              <td>{{ item.type }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mdiEye } from '@mdi/js'
export default {
  data() {
    return {
      loading_data: false,
      filters: {
        type: 'All',
        shop : ''
      },
      headers: [
        {
          text: 'Shop',
          value: 'specifier',
        },
        { text: 'Query ID', value: 'bulk_query_id' },
        { text: 'Created At', value: 'created_at' },
        { text: 'created_at', value: 'created_at' },
        { text: 'Poll Progress', value: 'poll_progress_count' },
        { text: 'Processed Count', value: 'processed_count' },
        { text: 'Step', value: 'step' },
        { text: 'Total Count', value: 'total_count' },
        { text: 'Total Count Precision', value: 'total_count_precision' },
      ],
      pagination: {
        page: 1,
        total: 0,
        per_page: 10,
      },
      bulkOperations: [],

      icons: {
        mdiEye: mdiEye,
      },

      is_first: true,
    }
  },
  mounted() {
    this.fetchOperations()
  },
  destroyed() {
  },
  methods: {
    async fetchOperations() {
      if (this.is_first == true) this.loading_data = true
      try {
        let response = await axios.get('/admin/bulk-operations', { params: this.filters })
        if (response.data.success) {
          this.bulkOperations = response.data.bulkOperations.data
          this.pagination.total = response.data.bulkOperations.total
          this.pagination.per_page = response.data.bulkOperations.per_page
          this.pagination.page = response.data.bulkOperations.current_page
          this.is_first = false
        }
      } catch (e) {
        console.log(e);

      } finally {
        this.loading_data = false
      }
    },

    // showDetails(item) {
    //   this.queue = item
    //   this.show_details = true
    // },
  },
}
</script>
